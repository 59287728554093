import { adminRoutes } from './adminRoutes'
import { shelterRoutes } from './shelterRoutes'
import { pantryRoutes } from './pantryRoutes'
import { sharedRoutes } from './sharedRoutes'
import { commonRoutes } from './commonRoutes'

const NAV_ORDER = {
  admin: [
    'dashboard',
    'shelters',
    'adoption',
    'orders',
    'stories',
    'emails',
    'payouts',
    'users'
  ],
  shelter: ['dashboard', 'donations', 'orders', 'payouts', 'emails', 'stories'],
  pantry: ['dashboard', 'donations', 'orders', 'emails', 'stories']
}

const routesConfig = [
  ...adminRoutes,
  ...shelterRoutes,
  ...pantryRoutes,
  ...sharedRoutes,
  ...commonRoutes
]

const getRoutes = (userType) => {
  let routes = []

  switch (userType) {
    case 'admin':
      routes = adminRoutes
      break
    case 'shelter':
      routes = shelterRoutes
      break
    case 'pantry':
      routes = pantryRoutes
      break
    default:
      break
  }

  const routesObj = [...sharedRoutes, ...routes, ...commonRoutes].reduce(
    (acc, route) => {
      acc[route.to] = route

      return acc
    },
    {}
  )

  return Object.values(routesObj)
}

export { routesConfig, getRoutes, NAV_ORDER }
