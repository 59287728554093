export default {
  SAGA_GET_GENERAL_METRICS: 'SAGA_GET_GENERAL_METRICS',
  SAGA_GET_GENERAL_METRICS_BY_TYPE: 'SAGA_GET_GENERAL_METRICS_BY_TYPE',
  SET_GENERAL_METRICS: 'SET_GENERAL_METRICS',
  SET_GENERAL_METRICS_BY_TYPE: 'SET_GENERAL_METRICS_BY_TYPE',
  SET_GENERAL_METRICS_BY_TYPE_LOADING: 'SET_GENERAL_METRICS_BY_TYPE_LOADING',
  UPDATE_PAYPAL_FORM: 'UPDATE_PAYPAL_FORM',
  UPDATE_STRIPE_FORM: 'UPDATE_STRIPE_FORM',
  SAGA_DISPATCH_PAYPAL_PAYOUT: 'SAGA_DISPATCH_PAYPAL_PAYOUT',
  SAGA_DISPATCH_STRIPE_PAYOUT: 'SAGA_DISPATCH_STRIPE_PAYOUT'
}
