import { call, all, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import actions from './actions'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const URL = '/api/admin/payouts/general-metrics'

// Axios calls
const getGeneralMetrics = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const getGeneralMetricsByType = async ({ payload }) => {
  const { type, ...rest } = payload

  return await axios.get(`${URL}/${type}`, { params: rest })
}

const postPaypaylPayout = async ({ payload }) => {
  return await axios.post('/api/admin/payouts/paypal', payload)
}

const postStripePayout = async ({ payload }) => {
  return await axios.post('/api/admin/payouts/stripe', payload)
}

// Doers
function* doGetGeneralMetrics(payload) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(getGeneralMetrics, payload)
    yield put(actions.setGeneralMetrics(data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetGeneralMetricsByType(payload) {
  try {
    yield put(actions.setGeneralMetricsByTypeLoading(true))
    const { data } = yield call(getGeneralMetricsByType, payload)
    yield put(actions.setGeneralMetricsByType(data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setGeneralMetricsByTypeLoading(false))
  }
}

function* doPostStripePayout(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(postStripePayout, payload)
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doPostPaypaylPayout(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(postPaypaylPayout, payload)
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

// Watchers
function* watchGetGeneralMetrics() {
  yield takeLatest(
    types.SAGA_GET_GENERAL_METRICS,
    safe(onError, doGetGeneralMetrics)
  )
}

function* watchGetGeneralMetricsByType() {
  yield takeLatest(
    types.SAGA_GET_GENERAL_METRICS_BY_TYPE,
    safe(onError, doGetGeneralMetricsByType)
  )
}

function* watchDoPostPaypaylPayout() {
  yield takeLatest(
    types.SAGA_DISPATCH_PAYPAL_PAYOUT,
    safe(onError, doPostPaypaylPayout)
  )
}

function* watchDoPostStripePayout() {
  yield takeLatest(
    types.SAGA_DISPATCH_STRIPE_PAYOUT,
    safe(onError, doPostStripePayout)
  )
}

export default function* rootSaga() {
  yield all([
    watchGetGeneralMetrics(),
    watchGetGeneralMetricsByType(),
    watchDoPostPaypaylPayout(),
    watchDoPostStripePayout()
  ])
}
