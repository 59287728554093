import types from './types'

const sagaGetGeneralMetrics = (payload) => {
  return {
    type: types.SAGA_GET_GENERAL_METRICS,
    payload
  }
}

const sagaDispatchPayoutStripe = (payload) => {
  return {
    type: types.SAGA_DISPATCH_STRIPE_PAYOUT,
    payload
  }
}

const sagaDispatchPayoutPaypal = (payload) => {
  return {
    type: types.SAGA_DISPATCH_PAYPAL_PAYOUT,
    payload
  }
}

const sagaGetGeneralMetricsByType = (payload) => {
  return {
    type: types.SAGA_GET_GENERAL_METRICS_BY_TYPE,
    payload
  }
}

const setGeneralMetrics = (generalMetrics) => {
  return {
    type: types.SET_GENERAL_METRICS,
    payload: generalMetrics
  }
}

const setGeneralMetricsByType = (generalMetricsByType) => {
  return {
    type: types.SET_GENERAL_METRICS_BY_TYPE,
    payload: generalMetricsByType
  }
}

const setGeneralMetricsByTypeLoading = (loading = false) => {
  return {
    type: types.SET_GENERAL_METRICS_BY_TYPE_LOADING,
    payload: loading
  }
}

const updatePaypalForm = (key, value) => {
  return {
    type: types.UPDATE_PAYPAL_FORM,
    key,
    value
  }
}

const updateStripeForm = (key, value) => {
  return {
    type: types.UPDATE_STRIPE_FORM,
    key,
    value
  }
}

const generalMetricsActions = {
  sagaGetGeneralMetrics,
  sagaGetGeneralMetricsByType,
  setGeneralMetrics,
  setGeneralMetricsByType,
  setGeneralMetricsByTypeLoading,
  updatePaypalForm,
  updateStripeForm,
  sagaDispatchPayoutStripe,
  sagaDispatchPayoutPaypal
}

export default generalMetricsActions
